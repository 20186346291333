import Button from "@openup/shared/components/Button/Button";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

type NextLessonButtonVariants = "right" | "wide";

type NextLessonButtonProps = {
  onNextStep: () => void;
  variant?: NextLessonButtonVariants;
  isLastLessonInCourse?: boolean;
};

export const NextLessonButton = ({
  onNextStep,
  variant = "right",
  isLastLessonInCourse = false,
}: NextLessonButtonProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={`
        px-8 sm:px-0 py-4 sm:py-0 flex justify-end mt-10
        bg-white sm:bg-transparent border-[0] border-t border-solid border-gray-200 sm:border-t-0
        fixed bottom-0 left-0 right-0 sm:static
      `}
    >
      <div
        className={`flex ${variant === "wide" ? " justify-center w-full" : "justify-end"}`}
      >
        <Button
          onClick={() => {
            onNextStep();
          }}
          className={
            variant === "wide"
              ? "w-full sm:w-[330px] max-w-full"
              : "w-full sm:w-auto"
          }
        >
          {isLastLessonInCourse
            ? t("CourseProgress.CompleteCourse")
            : t("CourseProgress.NextLesson")}
          {variant !== "wide" && (
            <ArrowForwardIcon className="hidden sm:inline-block" />
          )}
        </Button>
      </div>
    </div>
  );
};
